import ScreenStack from "../screens";

const RoutePath = {
  LOGIN: {
    accessible: true,
    component: <ScreenStack.LOGIN />,
    exact: true,
    path: "/login",
    title: "Login Page",
    type: "non-private",
  },

  DASHBOARD: {
    accessible: true,
    component: <ScreenStack.DASHBOARD />,
    exact: true,
    path: "/dashboard",
    title: "Dashboard",
    type: "private",
  },
  USER_MANAGEMENT: {
    accessible: true,
    component: <ScreenStack.USER_MANAGEMENT />,
    exact: true,
    path: "/user_management",
    title: "User Management",
    type: "private",
  },
  USER_DETAIL: {
    accessible: true,
    component: <ScreenStack.USER_DETAIL />,
    exact: true,
    path: "/user_detail",
    title: "User Detail",
    type: "private",
  },
  EDIT_USER_DETAIL: {
    accessible: true,
    component: <ScreenStack.EDIT_USER_DETAIL />,
    exact: true,
    path: "/edit_user",
    title: "Edit User",
    type: "private",
  },
  JOURNEY_MANAGEMENT: {
    accessible: true,
    component: <ScreenStack.JOURNEY_MANAGEMENT />,
    exact: true,
    path: "/journey_management",
    title: "Journey Management",
    type: "private",
  },
  JOURNEY_DETAIL: {
    accessible: true,
    component: <ScreenStack.JOURNEY_DETAIL />,
    exact: true,
    path: "/journey_detail",
    title: "Journey Detail",
    type: "private",
  },
  SKILLS_MANAGEMENT: {
    accessible: true,
    component: <ScreenStack.SKILLS_MANAGEMENT />,
    exact: true,
    path: "/skill_management",
    title: "skills Management",
    type: "private",
  },
  SKILL_DETAIL: {
    accessible: true,
    component: <ScreenStack.SKILL_DETAIL />,
    exact: true,
    path: "/skill_detail",
    title: "skill Detail",
    type: "private",
  },
  STEPS_DETAIL: {
    accessible: true,
    component: <ScreenStack.STEPS_DETAIL />,
    exact: true,
    path: "/step_detail",
    title: "Step Detail",
    type: "private",
  },
  EXERCISE_DETAIL: {
    accessible: true,
    component: <ScreenStack.EXERCISE_DETAIL />,
    exact: true,
    path: "/exercise_detail",
    title: "Exercise Detail",
    type: "private",
  },
  CONTENT_MANAGEMENT: {
    accessible: true,
    component: <ScreenStack.CONTENT_MANAGEMENT />,
    exact: true,
    path: "/content_management",
    title: "Content Management",
    type: "private",
  },
  LEADERBOARD_MANAGEMENT: {
    accessible: true,
    component: <ScreenStack.LEADERBOARD_MANAGEMENT />,
    exact: true,
    path: "/leaderboard_management",
    title: "Leaderboard",
    type: "private",
  },
  NOTIFICATION_MANAGEMENT: {
    accessible: true,
    component: <ScreenStack.NOTIFICATION_MANAGEMENT />,
    exact: true,
    path: "/notification_management",
    title: "Notifications",
    type: "private",
  },
  LIBRARY_MANAGEMENT: {
    accessible: true,
    component: <ScreenStack.LIBRARY_MANAGEMENT />,
    exact: true,
    path: "/library_management",
    title: "Library Management",
    type: "private",
  },
};
export { RoutePath };
