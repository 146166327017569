import React from "react";
import { ClipLoader } from "react-spinners";
import useGet from "../../hooks/useGet";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import { Rating } from "react-simple-star-rating";
import ImagesUrl from "../../utils/constants/images";

export default function ReviewModal({
  open,
  onClose,
  onConfirm,
  cancelTitle,
  actionTitle,
  actionButtonClass,
  isLoading,
  actionButtonNotRequire,
  resourceId,
}) {
  const { data: reviewData } = useGet(
    ENDPOINTS.REFLECTION_RATINGS,
    QUERY_KEYS.REFLECTION_RATINGS_QUERY_KEY,
    { form_id: "", resource_id: resourceId }
  );
  const reviewsData = reviewData?.data?.resources_ratings;

  if (!open) {
    return null;
  }
  return (
    <div
      className={`modal fade common-modal modal-xl show`}
      id="exampleModalCenter"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      style={{ display: "block", justifyContent: "center" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="common-body-modal">
              <div className="common-modal-header">
                <h5 className="common-modal-title">Reviews</h5>
              </div>
              <div className="common-modal-form">
                <div>
                  <div className="d-flex justify-content-between m-2">
                    {reviewsData?.length > 0 ? (
                      <div className="d-flex align-items-end w-100 gap-1">
                        <h6 className="m-0">Total :</h6>
                        <p className="m-0"> {reviewsData?.length}</p>
                      </div>
                    ) : null}
                  </div>
                  <div className="review_container">
                    {reviewsData?.length > 0 ? (
                      reviewsData?.map((item, i) => {
                        const formatDate = (dateString) => {
                          const options = {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          };
                          const date = new Date(dateString);
                          return date.toLocaleDateString("en-GB", options);
                        };
                        return (
                          <div
                            className="d-flex align-items-center justify-content-between border border-success rounded p-2"
                            key={i}
                          >
                            <div className="d-flex gap-3 align-items-center justify-content-center">
                              <img
                                className="rounded-circle"
                                height="25px"
                                width="25px"
                                src={ImagesUrl.empty_image}
                                alt="...."
                              />
                              <div className="content_div">
                                <p>
                                  {item?.first_name} {item?.last_name}
                                </p>
                                <p> {formatDate(item?.created_at)}</p>
                              </div>
                            </div>
                            <div>
                              <Rating
                                initialValue={item?.rating}
                                size="20"
                                readonly={true}
                                allowHover={false}
                              />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center d-flex justify-content-center align-items-center h-100">
                        <p className="mb-0">No ratings available.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="common-modal-footer  ">
                <button
                  type="button"
                  className="btn btn-green mb-0 text-white"
                  data-bs-dismiss="modal"
                  onClick={onClose}
                >
                  {cancelTitle}
                </button>
                {actionButtonNotRequire ? null : (
                  <button
                    type="button"
                    className={`btn  mb-0 text-white ${actionButtonClass}`}
                    onClick={onConfirm}
                  >
                    {isLoading ? (
                      <ClipLoader color="#0d6efd" size={15} />
                    ) : (
                      actionTitle
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
