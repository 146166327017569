/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef } from "react";
import ImagesUrl from "../../utils/constants/images";
import { FadeLoader } from "react-spinners";

const RangeForm = ({
  index,
  editTemplate,
  removeStatement,
  item,
  handleSubForm,
  isLoadingAddImages,
  handleFileInputMultiple,
}) => {
  const fileInput = useRef(null);

  const handleFileInput = (target) => {
    handleFileInputMultiple(index, target.files[0]);
    target.value = "";
  };

  const handleSelectUploadImage = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const previewImageHandler = (item) => {
    if (item.image.added_image === "") {
      return ImagesUrl.shape_image;
    } else if (item?.image?.added_image instanceof File) {
      return URL.createObjectURL(item?.image.added_image);
    } else {
      return `https://leadherself-api.csdevhub.com/src/public/form_images/${item.image.added_image}`;
    }
  };

  return (
    <div key={index} className="position-relative">
      {index > 0 && editTemplate ? (
        <i
          className="far fa-trash-alt text-danger border rounded-2 border-warning  p-2 position-absolute  cursor-pointer"
          style={{
            top: "10px",
            right: "20px",
          }}
          onClick={() => removeStatement(index)}
          aria-hidden="true"
        ></i>
      ) : null}

      <div
        className={
          index > 0 && editTemplate
            ? "d-flex border border-success p-1"
            : "d-flex "
        }
        style={{ gap: "20px", borderRadius: "20px" }}
      >
        <div className="screen-layout">
          <div className="screen-indicator">
            <span className="time-indicator">9:41</span>
            {index < 1 || !editTemplate ? (
              <span className="mobile-indicator">
                <img src={ImagesUrl.mobile_Indicator} alt="Indicator" />
              </span>
            ) : null}
          </div>
          <div className="screen-header">
            <span className="back-btn">
              <img src={ImagesUrl.back_arrow} alt="Arrow" />
            </span>
            <h2 contentEditable="false" />
          </div>
          <div className="header-indicator-bar">
            <span className="filled-bar" />
            <span className="unfilled-bar" />
          </div>
          <div className="screen-body">
            <div
              className="resources-wrapper"
              style={{ maxHeight: "300px", marginTop: "-1px" }}
            >
              <div className="upload-img-blk rect-box">
                <img
                  key={index}
                  className="preview-img"
                  src={previewImageHandler(item, index)}
                  alt="Image"
                />

                <div className="upload-img-file">
                  <input
                    type="file"
                    className="d-none"
                    ref={fileInput}
                    onChange={({ target }) => handleFileInput(target)}
                  />
                  <label
                    htmlFor="ImageUpload"
                    onClick={editTemplate ? handleSelectUploadImage : null}
                  >
                    <i className="fa-solid fa-image" />
                  </label>
                </div>
              </div>
              <div
                className="checkbox-wrapper"
                style={{ maxHeight: "214px", marginTop: "0px" }}
              >
                <div className="checkbox-list">
                  <div className="screen-content">
                    <p
                      contentEditable={editTemplate}
                      onBlur={(e) => handleSubForm(e, index, "description")}
                    >
                      {item?.description}
                    </p>
                  </div>
                </div>
              </div>

              <div className="range-blk">
                <div className="range-bar">
                  <img src={ImagesUrl?.range_slider} alt="Slider" />
                </div>
                <div className="flex-sb-box">
                  <div className="flex-sb-content">
                    <p
                      contentEditable={editTemplate}
                      style={{ textAlign: "left" }}
                      onBlur={(e) => handleSubForm(e, index, "rang_left_title")}
                    >
                      {item?.rang_left_title}
                    </p>
                  </div>
                  <div className="flex-sb-content">
                    <p
                      contentEditable={editTemplate}
                      style={{ textAlign: "right" }}
                      onBlur={(e) =>
                        handleSubForm(e, index, "range_right_title")
                      }
                    >
                      {item?.range_right_title}
                    </p>
                  </div>
                </div>
                <div className="reflection-item w-100 note-text-div mt-2">
                  <p>Agree</p>
                  <div className="reflection-quote">
                    <p
                      className="feedback-inner-text"
                      contentEditable="true"
                      style={{ fontSize: 12, color: "rgb(0, 105, 114)" }}
                      onBlur={(e) => handleSubForm(e, index, "agree")}
                    >
                      {item?.agree}
                    </p>
                  </div>
                </div>
                <div className="reflection-item w-100 note-text-div mt-2">
                  <p>Neutral</p>
                  <div className="reflection-quote">
                    <p
                      className="feedback-inner-text"
                      contentEditable="true"
                      style={{ fontSize: 12, color: "rgb(0, 105, 114)" }}
                      onBlur={(e) => handleSubForm(e, index, "neutral")}
                    >
                      {item?.neutral}
                    </p>
                  </div>
                </div>
                <div className="reflection-item w-100 note-text-div mt-2">
                  <p>Disagree</p>
                  <div className="reflection-quote">
                    <p
                      className="feedback-inner-text"
                      contentEditable="true"
                      style={{ fontSize: 12, color: "rgb(0, 105, 114)" }}
                      onBlur={(e) => handleSubForm(e, index, "disagree")}
                    >
                      {item?.disagree}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="screen-footer">
            <div className="footer-nav-btn">
              <div
                className="btn-text-style"
                contentEditable={editTemplate}
                onBlur={(e) => handleSubForm(e, index, "buttonTitle")}
              >
                {item?.buttonTitle}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeForm;
